<template>
  <div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-60 md-size-50"
      ></div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-40 md-size-50"
      >
        <div
          class="crud-add-button"
          v-if="$store.getters.getPermission('slots_create')"
        >
          <div>
            <router-link
              :to="{
                name: 'slotCreate',
                params: { device: $route.params.device },
              }"
            >
              <span class="material-icons"> add_circle </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <datatables-wrapper
      @optionsChanged="getItems"
      @updateTable="getItems"
      :loading="loading"
      :data="data"
      :headers="headers"
      filterType="simple"
      :parameters="['alais']"
      @rowClick="rowClick"
      :parentSlots="parentSlots"
    >
      <template v-slot:alias="{ item }" v-if="showIcon">
        <v-icon color="primary" size="20" v-if="item.icon">
          {{ item.icon.layoutConfiguration.icon }}</v-icon
        >
        {{ item.alias }}
      </template>
      <template v-slot:alias="{ item }" v-else>
        {{ item.alias }}
      </template></datatables-wrapper
    >
  </div>
</template>

<script>
import DatatablesWrapper from "@/components/Datatables/DatatablesWrapper.vue";
export default {
  name: "slots-crud",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  components: {
    DatatablesWrapper,
  },
  data() {
    return {
      data: {},
      deviceDisplayers: [],
      showIcon: false,
      showLastSeen: false,
      loading: false,
      headers: [
        {
          text: "Alias",
          align: "start",
          sortable: false,
          value: "alias",
        },
        { text: "Nombre de parametro", value: "parameterName" },
        { text: "Timestamp", value: "timestamp" },
      ],
      parentSlots: [{ name: "alias", slotName: "item.alias" }],
    };
  },
  methods: {
    goToProfile(slot) {
      this.$router.push({
        name: "slotDetails",
        params: { device: this.$route.params.device, slot },
      });
    },
    getItems(options) {
      this.loading = true;
      this.$store
        .dispatch("slot/getSlots", {
          device: this.$route.params.device,
          options,
        })
        .then((data) => (this.data = data))
        .then(() => {
          this.getDeviceDisplayers();
        })
        .finally(() => (this.loading = false));
    },
    rowClick(event) {
      this.$router.push({
        name: "slotDetails",
        params: { device: this.$route.params.device, slot: event.id },
      });
    },
    getDeviceDisplayers() {
      this.$store
        .dispatch("displayers/getDeviceDisplayers", this.$route.params.device)
        .then((data) => {
          this.deviceDisplayers = data;
        })
        .then(() => {
          this.setIcono();
        })
        .catch((err) => console.error(err));
    },
    setIcono() {
      this.data.data.forEach((slot) => {
        slot.icon = this.deviceDisplayers.find(
          (displayer) =>
            slot.id == displayer.slots[0].id &&
            displayer.layoutConfiguration.icon
        );
      });
      setTimeout(() => {
        this.showIcon = true;
      }, 100);
    },
    getSlotMeasurement(slot) {
      this.$store
        .dispatch("monitoring/getSlotMeasurement", slot.id)
        .then((measurement) => {
          slot.lastSeen = measurement.measurement.timestamp;
        });
    },
    setLastSeen() {
      this.data.data.forEach((slot) => {
        this.getSlotMeasurement(slot);
      });
    },
  },
  mounted() {
    this.getItems({ page: 1 });
  },
};
</script>