<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-content>
          <slots-crud :table-header-color="getTheme"></slots-crud>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import SlotsCrud from "../../components/Slots/SlotsCrud";
import { mapGetters } from "vuex";
export default {
  components: {
    SlotsCrud,
  },
  computed: {
    ...mapGetters(["getTheme"]),
  },
};
</script>